import one from '../assets/img/gallery/1.jpeg';
import two from '../assets/img/gallery/2.jpeg';
import three from '../assets/img/gallery/3.jpeg';
import four from '../assets/img/gallery/4.jpeg';
import five from '../assets/img/gallery/5.jpeg';
import six from '../assets/img/gallery/6.jpeg';
import seven from '../assets/img/gallery/7.jpeg';
import eight from '../assets/img/gallery/8.jpeg';
import nine from '../assets/img/gallery/9.jpeg';
import ten from '../assets/img/gallery/10.jpeg';
import elevin from '../assets/img/gallery/11.jpeg';
import twelve from '../assets/img/gallery/12.jpeg';

const files = [
  {
    original: one,
    thumbnail: one
  },
  {
    original: two,
    thumbnail: two
  },
  {
    original: three,
    thumbnail: three
  },
  {
    original: four,
    thumbnail: four
  },
  {
    original: five,
    thumbnail: five
  },
  {
    original: six,
    thumbnail: six
  },
  {
    original: seven,
    thumbnail: seven
  },
  {
    original: eight,
    thumbnail: eight
  },
  {
    original: nine,
    thumbnail: nine
  },
  {
    original: ten,
    thumbnail: ten
  },
  {
    original: elevin,
    thumbnail: elevin
  },
  {
    original: twelve,
    thumbnail: twelve
  },
]

export default files;