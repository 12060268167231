// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/img/wash.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/img/cover.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-image {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image-diff {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  /* background-image: url('./assets/img/gallery/10.jpeg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-slide {
  max-height: 890px; /* Set your desired max height */
  overflow: hidden; /* Manage overflow if content exceeds max height */
}

.swiper-button-next,
.swiper-button-prev {
  color: #ff0000; /* Change to your desired color */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yDAA8C;EAC9C,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,yDAAgD;EAChD,2DAA2D;EAC3D,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,iBAAiB,EAAE,gCAAgC;EACnD,gBAAgB,EAAE,kDAAkD;AACtE;;AAEA;;EAEE,cAAc,EAAE,iCAAiC;AACnD","sourcesContent":[".bg-image {\n  background-image: url('./assets/img/wash.jpg');\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.bg-image-diff {\n  background-image: url('./assets/img/cover.jpeg');\n  /* background-image: url('./assets/img/gallery/10.jpeg'); */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n.custom-slide {\n  max-height: 890px; /* Set your desired max height */\n  overflow: hidden; /* Manage overflow if content exceeds max height */\n}\n\n.swiper-button-next,\n.swiper-button-prev {\n  color: #ff0000; /* Change to your desired color */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
