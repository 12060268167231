import './App.css';
import './assets/css/style.css';
import '@fortawesome/fontawesome-free/css/all.css';
import c1 from './assets/img/c1.jpeg';
import c2 from './assets/img/c2.jpeg';
import c3 from './assets/img/gallery/10.jpeg';
import c4 from './assets/img/gallery/11.jpeg';
import c5 from './assets/img/gallery/12.jpeg';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/style.min.css';
import logo from './assets/img/logo.png';
import aboutCover from './assets/img/about.jpeg';
import b1 from './assets/img/blog-1.jpg';
import b2 from './assets/img/blog-2.jpg';
import b3 from './assets/img/blog-3.jpg';
import ReactPlayer from 'react-player'
import TourFlim from './assets/img/laundryTour.mp4';
import ImageGallery from "react-image-gallery";
import files from './data/galleryImages';
import 'react-image-gallery/styles/css/image-gallery.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Thumbsnail from './assets/img/gallery/12.jpeg';
import clock from './assets/img/clock.jpg';
import comingSoon from './assets/img/comingsoon.gif';
import availableNow from './assets/img/availableNow.gif';
import Steps from './compoenets/steps';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/a11y';

function App() {
  const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2
      },
    },
  };

  return (
    <div className="App" id='top'>
      <div class="container-fluid bg-primary py-3">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-lg-right">
              <div class="d-inline-flex align-items-center">
                <a class="text-white px-3" href="https://www.facebook.com/profile.php?id=61557035848452&mibextid=LQQJ4d" target='_blank'>
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a class="text-white px-3" href="https://www.instagram.com/laundrobaroceangrove?igsh=MTl0ejh2NzRvaTk5OQ%3D%3D" target='_blank'>
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid position-relative nav-bar p-0 bg-primary">
        <div class="container-lg position-relative p-0 px-lg-3" style={{zIndex: 9}}>
          <nav class="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 pl-3 pl-lg-5">
            <a href="" class="navbar-brand">
              <img src={logo} style={{ maxHeight: '80px', marginRight: '8px'}} />
            </a>
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-between px-3" id="navbarCollapse">
              <div class="navbar-nav ml-auto py-0">
                <a href="/" class="nav-item nav-link">HOME</a>
                <a href="#about" class="nav-item nav-link">ABOUT</a>
                <a href="#service" class="nav-item nav-link">SERVICES</a>
                <a href='#prices' className='nav-item nav-link'>PRICES</a>
                <a href="#testimonial" class="nav-item nav-link">TESTIMONIALS</a>
                <a href="#directions" class="nav-item nav-link">DIRECTIONS</a>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div class="container-fluid bg-image p-0">
        <section id="" class="dark-background">
          <div class="carousel">
            <Swiper
              loop={true}
              modules={[Navigation, Pagination, Autoplay, A11y]}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: false }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide className='custom-slide'>
                <img src={c1} alt=""/>
              </SwiperSlide>

              <SwiperSlide className='custom-slide'>
                <img src={c2} alt=""/>
              </SwiperSlide>

              <SwiperSlide className='custom-slide'>
                <img src={c3} alt=""/>
              </SwiperSlide>

              <SwiperSlide className='custom-slide'>
                <img src={c4} alt=""/>
              </SwiperSlide>

              <SwiperSlide className='custom-slide'>
                <img src={c5} alt=""/>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </div>

      <div class="container-fluid mt-5 mb-4">
        <div class="container" style={{padding: '0 30px'}}>
          <div class="row">
            <div class="col-md-4 d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0" style={{height: '130px'}}>
              <div class="d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0">
                <i class="fa fa-2x fa-map-marker-alt text-white m-0 mr-3"></i>
                <div class="d-flex flex-column">
                  <p class="m-0 text-white">
                    <b>
                      KINGSTON VILLAGE SHOPPING CENTRE 
                      T23/12 - 22 Coastal Boulevard, Ocean Grove, VIC 3226
                      (Next to ALDI & Zembrero)
                    </b>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 d-flex align-items-center justify-content-center bg-primary mb-4 mb-lg-0" style={{height: '130px'}}>
              <div class="d-inline-flex">
                <div class="d-flex">
                  <a class="text-white px-3" href="https://www.facebook.com/profile.php?id=61557035848452&mibextid=LQQJ4d" target='_blank'>
                    <i class="fab fa-2x fa-facebook-f"></i>
                  </a>

                  <a class="text-white px-3" href="https://www.instagram.com/laundrobaroceangrove?igsh=MTl0ejh2NzRvaTk5OQ%3D%3D" target='_blank'>
                    <i class="fab fa-2x fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-4 d-flex align-items-center justify-content-center bg-secondary mb-4 mb-lg-0" style={{height: '130px'}}>
              <div class="d-inline-flex text-left">
                <i class="fa fa-2x fa-phone-alt text-white m-0 mr-3"></i>
                <div class="d-flex flex-column">
                    <p class="m-0 text-white"><b>+61 416 970 009</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container rounded mt-5 mb-4">
        <div class="container" style={{padding: '0 30px'}}>
          <div class="row align-items-center">
            <div class="col-lg-5">
              <img class="img-fluid" src={clock} alt="" />
            </div>
            <div className='col-lg-7 mt-5 mt-lg-0 pl-lg-5'>
              <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Timings</h6>
              <h1 className='mb-4'>Welcome to Laundrobar Laundromat — Serving Ocean Grove and nearby suburbs</h1>
              <h5 className='font-weight-medium font-italic mb-4'>Open 6am - Midnight, 365 Days</h5>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="container-fluid py-5">
        <div class="container pt-0 pt-lg-4">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <img class="img-fluid" src={aboutCover} alt="" />
            </div>
            <div class="col-lg-7 mt-5 mt-lg-0 pl-lg-5">
              <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Learn About Us</h6>
              <h1 class="mb-4">The Premier Laundry Service in Town.</h1>
              <h5 class="font-weight-medium font-italic mb-4">Enjoy self operated laundry service in you local area with a focus on quality, efficiency and customer satisfaction.</h5>
              <div class="row">
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Quality Laundry Service</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                      <i class="fa fa-check text-primary mr-2"></i>
                      <p class="text-secondary font-weight-medium m-0">100% Satisfaction Guarantee</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Highly Professional Staff</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Hygienic environment</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Coffee Station</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-image-diff container-fuild bg-secondary mt-5 mb-4 p-2">
        <div class="container-fuild" style={{padding: '0 30px'}}>
          <div class="row justify-content-center">
            <div className='col-lg-5 rounded p-5 m-4 bg-light'>
              <h5 className='font-weight-medium font-italic mb-4'>Who can benefit from Laundrobar?</h5>

              <div className='mt-4'>
                <h6 class="text-secondary text-uppercase font-weight-medium mb-3">GENERAL PUBLIC</h6>
                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Oversized items, including king and queen-size doonas, blankets, and pillows</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Students (Clothing, including school uniforms)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Busy households (All wearable clothing, bedding, towels)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Travelers (All types of wearable clothing)</li>
                </div>
              </div>

              <div className='mt-4'>
                <h6 class="text-secondary text-uppercase font-weight-medium mb-3">BUSINESSES</h6>
                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Gyms (Towels and workout uniforms)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Daycare/Schools (Uniforms)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Sports Clubs (Team uniforms)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Hospitality (Chair covers, tablecloths, uniforms, bedding)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Physios (Towels, uniforms, linens)</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Hairdressers (Aprons, towels, uniforms)</li>
                </div>
              </div>
            </div>

            <div className='col-lg-5 rounded p-5 m-4 bg-light'>
              <h5 className='font-weight-medium font-italic mb-4'>A straightforward and affordable premium wash, dry, and fold service.</h5>

              <div className='mt-4'>
                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">New commercial Premium HUEBSCH washers and dryers</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Innovative machines with proven advanced technologies</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Uncompromising performance, reliable wash</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Eco Friendly</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Coffee Station</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">MED, LRG & XLRG washers</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">LRG & XLRG dryers</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Detergent provided on site free of charge for our customers</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Folding stations</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">CCTV security</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Coins and Card service available for payment</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Facilities cleaned daily</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Comfortable Environment</li>
                </div>

                <div class="d-flex align-items-center">
                  <hr />
                  <li class="text-primary font-weight-medium m-0">Plenty of parking available at the back and front for convenient access.</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-light">
        <div class="container">
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <img src={availableNow} />

            <div class="testimonial-item">
              <div class="bg-light text-center p-5">
                <p class="m-0"><b>Wash, dry and fold service is now live and we are accepting the requests. You can contact on the number provided.</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid pt-5 pb-3" id='service'>
        <div class="container">
          <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Services</h6>
          <h1 class="display-4 text-center mb-5">What We Offer</h1>
          <div class="d-flex flex-column align-items-center justify-content-cente">
            <div class="pb-1">
              <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: '300px'}}>
                <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: '100px', height: '100px'}}>
                  <i class="fa fa-3x fa-soap text-secondary"></i>
                </div>
                <h4 class="font-weight-bold m-0">All type of laundry (wash and dry)</h4>
              </div>
            </div>

            <div class="col-lg-12 col-md-6 pb-1 d-flex flex-column align-items-center justify-content-center">
              <div class="d-flex flex-column jutify-content-center align-items-center justify-content-center text-center mb-4 px-4">
                <img src={comingSoon} style={{ height: '200px' }} />
              </div>
              <div className='row align-items-center justify-content-center'>
                <div class="col-lg-4 col-md-8 mb-2">
                  <div class="shadow mb-4">
                    <p class="p-4 font-weight-medium text-secondary font-italic mb-3">We are introducing loyalty card program for our customers to gain access to discounts and offers. Coming soon. Stay tuned!!!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="prices" class="container-fluid py-5">
        <div class="container pt-0 pt-lg-4">
          <div class="d-flex align-items-center justify-content-center">
            <div class="col-lg-7 mt-5 mt-lg-0 pl-lg-5">
              <h1 class="mb-4">SELF-SERVICE LAUNDROMAT WASH & DRY PRICES</h1>
              <h6 className='text-secondary text-uppercase text-center font-weight-medium mb-3'>Complimentary Soap available for use</h6>
              <div class="row">
                <div class="col-lg-6 pt-3 bg-primary">
                  <div class="d-flex flex-column justify-content-center align-items-center rounded">
                    <h6 class="text-uppercase text-center font-weight-medium mb-3">WASH</h6>

                    <table className='table text-light'>
                      <tr>
                        <th>Weight</th>
                        <th>Price</th>
                      </tr>
                      <tr>
                        <th>9 KG</th>
                        <th>$7</th>
                      </tr>
                      <tr>
                        <th>13.6 KG</th>
                        <th>$10</th>
                      </tr>
                      <tr>
                        <th>18 KG</th>
                        <th>$13</th>
                      </tr>
                      <tr>
                        <th>27 KG</th>
                        <th>$16</th>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="col-lg-6 pt-3 bg-secondary">
                  <div class="d-flex flex-column justify-content-center align-items-center rounded">
                    <h6 class="text-uppercase text-center font-weight-medium mb-3">DRY</h6>

                    <table className='table text-light'>
                      <tr>
                        <th>Weight</th>
                        <th>Price</th>
                      </tr>
                      <tr>
                        <th>14 KG</th>
                        <th>$1 / 5 mins</th>
                      </tr>
                      <tr>
                        <th>20 KG</th>
                        <th>$1 / 7 mins</th>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Steps />

      <div class="container-fluid bg-light pt-5 pb-3">
        <div class="container">
          <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Watch</h6>
          <h1 class="display-4 text-center mb-5">Laundry Tour</h1>
          <div class="row">
            <ReactPlayer url={TourFlim} playing controls forceVideo light={Thumbsnail} width='100%' height='800px' />
          </div>
        </div>

        <div class="container mt-4">
          <h1 class="display-4 text-center mb-5">Gallery</h1>
          <div class="row">
            <ImageGallery items={files} fullscreen />
          </div>
        </div>
      </div>

      <div class="container-fluid py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 m-0 my-lg-5 pt-0 pt-lg-5 pr-lg-5">
              <h6 class="text-secondary text-uppercase font-weight-medium mb-3">Our Features</h6>
              <h1 class="mb-4">Why Choose Us</h1>
              <div class="row">
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Latest technology Machines </p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                      <i class="fa fa-check text-primary mr-2"></i>
                      <p class="text-secondary font-weight-medium m-0"> ⁠⁠Huge 27 Kgs Washers for Big load</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠Different size of washers and dryers available to suit your needs</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠Regular Sanitisation of equipments</p>
                  </div>
                </div>

                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠After hours access available for professionals if required</p>
                  </div>
                </div>
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Free detergent available for customers </p>
                  </div>
                </div>
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠Free Wifi</p>
                  </div>
                </div>
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠Convenient payment method</p>
                  </div>
                </div>
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">⁠⁠Receipts available if required</p>
                  </div>
                </div>
                <div class="col-sm-6 pt-3">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-check text-primary mr-2"></i>
                    <p class="text-secondary font-weight-medium m-0">Neat and Clean environment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid py-5" id='testimonial'>
        <div class="container">
          <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Testimonial</h6>
          <h1 class="display-4 text-center mb-5">Our Clients Say</h1>
          <OwlCarousel loop margin={10} {...options}>
            <div class="testimonial-item m-1">
              <div class="bg-light text-center p-5">
                <h5 class="font-weight-medium">Gurpreet Singh</h5>
                <p class="m-0">Wow this is the cleanest laundramat i have ever been to with big 27 kg washers. Went there by recommendation and will be back. Excellent</p>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <div class="container-fluid mt-5 pb-2">
        <div class="container">
          <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Our Blog</h6>
          <h1 class="display-4 text-center mb-5">Latest From Blog</h1>
          <div class="row">
            <div class="col-lg-4 mb-2">
              <div class="shadow mb-4">
                <div class="position-relative">
                  <img class="img-fluid w-100" src={b1} alt="" />
                  <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: '0', left: '0', background: 'rgba(0, 0, 0, .4)'}}>
                    <h4 class="text-center text-white font-weight-medium mb-3">The Ultimate Guide to Fabric Care</h4>
                  </a>
                </div>
                <p class="m-0 p-4">Discover expert tips and tricks for maintaining the quality of your favorite garments. From washing and drying to stain removal, this guide covers everything you need to know to extend the life of your clothes.</p>
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="shadow mb-4">
                <div class="position-relative">
                  <img class="img-fluid w-100" src={b2} alt="" />
                  <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: '0', left: '0', background: 'rgba(0, 0, 0, .4)'}}>
                    <h4 class="text-center text-white font-weight-medium mb-3">Eco-Friendly Laundry</h4>
                  </a>
                </div>
                <p class="m-0 p-4">Learn how to reduce your environmental footprint with sustainable laundry practices. This blog explores energy-efficient machines, eco-friendly detergents, and simple changes that make a big impact on the planet.</p>
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="shadow mb-4">
                <div class="position-relative">
                  <img class="img-fluid w-100" src={b3} alt="" />
                  <a href="" class="position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center   text-decoration-none p-4" style={{top: '0', left: '0', background: 'rgba(0, 0, 0, .4)'}}>
                    <h4 class="text-center text-white font-weight-medium mb-3">Why Professional Laundry Services Save You Time and Money</h4>
                  </a>
                </div>
                <p class="m-0 p-4">Find out how outsourcing your laundry to professionals can be more cost-effective and time-saving than doing it yourself. Explore the benefits of expert care, from specialized treatments to convenient pick-up and delivery options.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container' id='directions'>
        <div class="container-fluid mt-5 pb-2">
          <div class="container">
            <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Directions</h6>
          </div>
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3133.3986255357618!2d144.53740737630645!3d-38.24705987187023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad439a3828344c7%3A0x9c319c43586652d5!2sLaundrobar(Ocean%20Grove)!5e0!3m2!1sen!2s!4v1723274310858!5m2!1sen!2s" width="100%" height="450" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <div class="container-fluid text-white mt-5 pt-5 px-sm-3 px-md-5" style={{backgroundColor: '#194377'}}>
        <div class="row pt-5">
          <div class="col-lg-4 col-md-6 mb-5">
            <p>At Laundrobar, we endeavour to provide the best care and customer service. Our laundry and equipments are very well maintained and serviced to ensure you get good quality service and your garments come out fresh, clean and in perfect condition.</p>
            <div class="d-flex justify-content-start mt-4">
              <a class="btn btn-outline-light rounded-circle text-center mr-2 px-0" style={{width: '38px', height: '38px'}} href="#"><i class="fab fa-facebook-f" href='https://www.facebook.com/profile.php?id=61557035848452&mibextid=LQQJ4d' target='_blank'></i></a>
              <a class="btn btn-outline-light rounded-circle text-center mr-2 px-0" style={{width: '38px', height: '38px'}} href="#"><i class="fab fa-instagram" href='https://www.instagram.com/laundrobaroceangrove?igsh=MTl0ejh2NzRvaTk5OQ%3D%3D' target='_blank'></i></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-5">
            <h4 class="text-white mb-4">Get In Touch</h4>
            <p>
              <i class="fa fa-map-marker-alt mr-2"></i>
              KINGSTON VILLAGE SHOPPING CENTRE 
              T23/12 - 22 Coastal Boulevard, Ocean Grove, VIC 3226

              (Next to ALDI & Zembrero)
            </p>
            <p><i class="fa fa-phone-alt mr-2"></i>+61 416 970 009</p>
          </div>
          <div class="col-lg-4 col-md-6 mb-5">
            <h4 class="text-white mb-4">Quick Links</h4>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-white mb-2" href="/"><i class="fa fa-angle-right mr-2"></i>Home</a>
              <a class="text-white mb-2" href="#about"><i class="fa fa-angle-right mr-2"></i>About Us</a>
              <a class="text-white mb-2" href="#service"><i class="fa fa-angle-right mr-2"></i>Services</a>
              <a class="text-white mb-2" href="#testimonial"><i class="fa fa-angle-right mr-2"></i>Testimonial</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg-dark text-white py-4 px-sm-3 px-md-5">
        <p class="m-0 text-center text-white">
          &copy; <a class="text-white font-weight-medium" href="#">Laundrobar</a>. All Rights Reserved. 
        
          Developed by <a class="text-white font-weight-medium" href="https://www.linkedin.com/in/saim-sohail-584a6521a/">Spiral Square</a>
        </p>
      </div>

      <a href="#root" class="btn btn-lg btn-primary back-to-top"><i class="fa fa-angle-double-up"></i></a>
    </div>
  );
}

export default App;
