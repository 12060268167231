import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Grid, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Steps() {
  return (
    <div class="container-fluid pt-5 pb-3" id='service'>
      <div class="container">
        <h1 class="display-4 text-center mb-5">Quick and Easy Washing Tips</h1>
        <h6 class="text-secondary text-uppercase text-center font-weight-medium mb-3">Easy Steps to Wash and Dry a Week’s Laundry in Under an Hour</h6>
        <Swiper
          modules={[Navigation, Pagination, Grid, Autoplay]}
          loop={true}
          spaceBetween={10}
          slidesPerView={3}
          Navigation
          pagination={{
            clickable: true,
            dynamicBullets: true,
            type: 'bullets',
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide>
            <div class="pb-1">
              <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: '300px'}}>
                <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: '100px', height: '100px'}}>
                <i class="fa fa-3x fa-soap text-secondary"></i>
                </div>
                <h4 class="font-weight-bold m-0">Step 1</h4>
                <p>
                  Select the appropriate washer or dryer for your laundry load. Make sure to empty all pockets before loading.
                </p>
                <small>(Make sure the unit is empty before use, and for the best results, avoid overloading the machine.)</small>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div class="pb-1">
              <div class="d-flex flex-column align-items-center justify-content-center text-center bg-light mb-4 px-4" style={{height: '300px'}}>
                <div class="d-inline-flex align-items-center justify-content-center bg-white shadow rounded-circle mb-4" style={{width: '100px', height: '100px'}}>
                  <i class="fa-solid fa-3x fa-money-bill text-secondary"></i>
                </div>
                <h4 class="font-weight-bold m-0">Step 2</h4>
                <p>
                  Please select extra rinse , wash or dry time as per your preference on the touch screen before finalising the payment.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Steps;